<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="给活动添加商品"
      :before-close="closeDialog"
      :visible.sync="showDialog"
      width="60%"
    >
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <el-input class="searchInput" placeholder="请输入商品名称" v-model="searchValue.productName" clearable>
          </el-input>
        </el-col>

        <el-col :span="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button
            type="primary"
            @click="
              () => {
                searchValue.page = 1
                getProductList()
              }
            "
          >
            搜索</el-button
          ></el-col
        >
      </el-row>
      <el-table
        ref="multipleTable"
        class="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" label="商品主图">
          <template slot-scope="scope">
            <img class="skuImg" :src="scope.row.productImageUrl | thumbMedium" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="productName" label="商品名称"> </el-table-column>
        <el-table-column align="center" label=" 价格" prop="firstSkuSalePrice"> </el-table-column>
        <el-table-column align="center" label="销量" prop="saleCount"> </el-table-column>
        <el-table-column align="center " label="剩余库存" prop="residueCount"> </el-table-column>

        <el-table-column align="center" label="创建时间">
          <template slot-scope="scope">
            <div>{{ scope.row.createTime | formatTime }}</div>
          </template>
        </el-table-column>
      </el-table>

      <div class="footer">
        <el-pagination
          :current-page="searchValue.page"
          @current-change="dialogHandleCurrentChange"
          :total="total"
          layout="total,  prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="closeDialog">取消</el-button>
        <el-button round type="primary" @click="confirmRelate()" :disabled="multipleSelection.length === 0"
          >添加活动商品</el-button
        >
      </span></el-dialog
    >
  </div>
</template>
<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      total: 0,
      searchValue: {
        productName: '',
        page: 1,
        productGroupId: this.$route.query.id,
        businessDistrictId: this.$route.query.businessDistrictId
      },
      list: [],
      multipleSelection: [],
      headers: {}
    }
  },

  created() {
    if (sessionStorage.currentAreaId == -1) {
      this.headers = {
        ['Bm-Area-Id']: this.$route.query.areaId
      }
    }
  },

  watch: {
    showDialog: {
      handler(n, o) {
        console.log(n, o)
        this.getProductList()
      },
      immediate: true
    }
  },
  methods: {
    // ======================================= 弹窗关联商品==================
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    closeDialog() {
      this.$emit('closeDialog', { close: false, getList: false })
    },
    resetSearch() {
      this.searchValue.productName = ''
      this.searchValue.page = 1
      this.getProductList()
    },

    getProductList() {
      this.$api.product
        .product('getNotRelationProduct', { params: this.searchValue, headers: this.headers })
        .then(res => {
          let { list, total } = res
          this.list = list
          this.total = total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    confirmRelate() {
      let arr = []
      this.multipleSelection.forEach(i => arr.push(i.productId))
      this.$api.product
        .product(
          'activityRelationProduct',
          {
            productGroupId: this.$route.query.id,
            productIdList: arr
          },
          {
            headers: this.headers
          }
        )
        .then(res => {
          this.$message.success('添加成功')
          this.$emit('closeDialog', { close: false, getList: true })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    dialogHandleCurrentChange(val) {
      this.searchValue.page = val
      this.getProductList()
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin: 20px 0;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.skuImg {
  width: 50px;
  height: 50px;
}
</style>
