<route>
{
  "meta": {
    "auth": "productActivity"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="searchText">商品名称</span>
          <el-input class="searchInput" v-model="searchValue.productName" placeholder="请输入商品名称" clearable>
          </el-input>
        </el-col>

        <el-col :span="12">
          <el-button type="info" @click="resetList()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>商品列表</span>
        <el-button
          v-auth="'productActivityAdd'"
          v-if="$route.query.editType == 'add'"
          type="success"
          @click="editProductRelate()"
          style="float: right"
          >添加</el-button
        >
        <el-button
          v-auth="'productActivityAdd'"
          type="success"
          @click="handleDragProduct()"
          v-if="$route.query.editType != 'check' && $route.query.productSortTypeShow == 0"
          style="float: right; margin-right: 10px"
          >拖动商品进行排序</el-button
        >
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" label="商品图">
          <template slot-scope="scope">
            <img class="productImgUrl" :src="scope.row.productImageUrl | thumbMedium" />
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="款式名称" align="center" width="300px"> </el-table-column>
        <el-table-column prop="firstSkuOriginalPrice" align="center" label="原价"> </el-table-column>
        <el-table-column prop="firstSkuSalePrice" align="center" label="售价"> </el-table-column>
        <el-table-column prop="saleCount" align="center" label="销量"> </el-table-column>
        <el-table-column prop="residueCount" align="center" label="剩余库存"> </el-table-column>
        <el-table-column prop="salePrice" align="center" label="上架状态">
          <template slot-scope="scope">
            {{ scope.row.shelfStatus ? '上架' : '下架' }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" v-if="!$route.query.check">
          <template slot-scope="scope">
            <el-button
              v-auth="'productActivityDelete'"
              @click="showDelete(scope)"
              type="text"
              size="small"
              class="deleteBtnText"
              v-if="$route.query.editType == 'add'"
              >删除</el-button
            >
            <el-button @click="switchHideStatus(scope)" type="text" size="small" v-else>{{
              scope.row.hideStatus === 1 ? '显示' : '隐藏'
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="searchValue.page"
          :page-sizes="pageSizes"
          :page-size="searchValue.size"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" title="删除该商品" :visible.sync="deleteDialog" width="30%">
      <span class="assist"> 您确定要删除该商品嘛？删除后不在活动里面显示？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmDelete">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="hideStatus.show"
      title="更改商品的显示/隐藏状态"
      width="300px"
    >
      <div style="text-align: center">
        {{ hideStatus.status === 1 ? '你是否要显示这个商品' : '你是否要隐藏这个商品' }}
      </div>

      <div slot="footer">
        <el-button round @click="hideStatus.show = false">取消</el-button>
        <el-button round type="primary" @click="confirmSwitchHideStatus">确定</el-button>
      </div>
    </el-dialog>

    <com-productList :showDialog="showDialog" @closeDialog="handleClose"></com-productList>

    <!-- 拖动进行排序 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="dragShow" width="1140px">
      <el-button type="primary" @click="sortProductByTime"> 按照商品上架时间最新到旧 </el-button>
      <el-button type="primary" @click="draggerListSearch"> 按照默认排序 </el-button>
      <draggable v-model="draggerList">
        <div v-for="(item, index) in draggerList" :key="index" class="listItem">
          <img class="img" :src="item.productImageUrl" />
          <span class="name">{{ item.productName }}</span>
          <span class="time">{{ item.onShelfTime | formatTime }}</span>
        </div>
      </draggable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dragShow = false"> 取消 </el-button>
        <el-button type="primary" @click="handleConfirmDragger"> 确定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import comProductList from './__com__/productList'
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  components: {
    draggable,
    comProductList
  },
  data() {
    return {
      deleteDialog: false,
      rowId: 0,
      list: [],
      draggerList: [],
      showDialog: false,
      searchValue: {
        productName: '',
        page: 1,
        size: 10
      },
      hideStatus: {
        id: '',
        show: false,
        hideStatus: 1
      },
      timestamp: 0,
      headers: {},
      dragShow: false
    }
  },

  created() {
    if (sessionStorage.currentAreaId == -1) {
      this.headers = {
        ['Bm-Area-Id']: this.$route.query.areaId
      }
    }
  },
  methods: {
    switchHideStatus({ row }) {
      this.hideStatus = {
        id: row.productId,
        show: true,
        status: row.hideStatus
      }
    },
    confirmSwitchHideStatus() {
      this.$api.product
        .product('reverseHideStatus', {
          productGroupId: this.$route.query.id,
          hideStatus: this.hideStatus.status ? 0 : 1,
          productId: this.hideStatus.id
        })
        .then(res => {
          this.hideStatus.show = false
          this.$message.success('修改成功')
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    // ====================================== 删除商品 ====================
    //删除关联的商品
    confirmDelete() {
      let params = {
        productGroupId: this.$route.query.id,
        productId: this.rowId
      }
      this.$api.product
        .product('deleteActivityProduct', params, { headers: this.headers })
        .then(res => {
          this.deleteDialog = false
          this.$message.success('删除成功')
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 显示提示确认删除关联商品的弹窗
    showDelete(scope) {
      this.deleteDialog = true
      this.rowId = scope.row.productId
    },
    handleFilter({ row }) {
      console.log(row)
    },

    // ======================================= 弹窗关联商品==================

    editProductRelate() {
      console.log(this.showDialog)
      this.showDialog = true
      console.log(this.showDialog)
    },
    handleClose(val) {
      this.showDialog = val.close
      if (val.getList) {
        this.getList()
      }
    },

    // ====================== 拖动已经关联活动的商品进行排序 ==========================

    sortProductByTime() {
      this.draggerList = this.draggerList.sort((a, b) => {
        return a.onShelfTime - b.onShelfTime
      })
    },
    handleConfirmDragger() {
      this.$http
        .put('/boom-center-admin-service/sysAdmin/productGroup/relationProduct/sorting', {
          productGroupId: this.$route.query.id,
          productIdList: this.draggerList.map(item => {
            return item.productId
          })
        })
        .then(res => {
          this.dragShow = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    handleDragProduct() {
      this.draggerListSearch()
      this.dragShow = true
    },

    // 搜索所有关联商品
    draggerListSearch() {
      this.loading = true
      let params = {
        productGroupId: this.$route.query.id,
        productName: this.productName
      }

      this.$http
        .get('/boom-center-admin-service/sysAdmin/productGroup/relationProduct/findList', {
          params: params,
          headers: this.headers
        })
        .then(res => {
          this.draggerList = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    // ===================================活动已经关联商品列表=========================================
    handleCurrentChange(val) {
      this.searchValue.page = val
      this.getList(true)
    },
    handleSizeChange(val) {
      this.searchValue.size = val
      this.getList()
    },
    // 重置搜索
    resetList() {
      this.searchValue = {
        productName: '',
        page: 1,
        size: 10
      }

      this.getList()
    },
    // 搜索该活动已经关联商品
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.searchValue.page : 1
      let params = {
        productGroupId: this.$route.query.id,
        ...this.searchValue
      }

      this.$http
        .get('/boom-center-admin-service/sysAdmin/productGroup/relationProduct/findPage', {
          params: params,
          headers: this.headers
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.productImgUrl {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.query {
  margin: 20px 0;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.listItem {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  align-items: center;
  .img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
  }
  .name {
    margin-top: 10px;
    width: 100px;
  }
  .time {
    margin-top: 10px;
    font-size: 10px;
  }
}
</style>
